import { Box, Flex } from '@chakra-ui/react';

import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';

const AboutIycSection = ({ sectionContent, region, lang }) => {
  return (
    <Box>
      <Box w="100%" backgroundColor="#b76707" maxW={'1300px'} mx="auto">
        <Box
          display="flex"
          justifyContent={{
            base: 'flex-start',
            md: 'center',
            lg: 'flex-start',
          }}
          py={{
            base: 'auto',
            sm: '320px',
            md: '30px',
            lg: '21px',
            xl: '28px',
          }}
          backgroundPosition={{ base: 'top', lg: 'center' }}
          backgroundSize={{ base: 'contain', lg: 'cover' }}
          backgroundRepeat="no-repeat"
          backgroundImage={{
            lg: `url(${sectionContent.body[4]?.sectionContent[1].image.url})`,
            md: `url(${sectionContent.body[4]?.sectionContent[1]?.mobileImage?.url})`,
            base: `url(${sectionContent.body[4]?.sectionContent[1]?.mobileImage?.url})`,
          }}
          // backgroundColor="#b76707"
          zIndex={'99999'}
        >
          <Flex
            w={{ base: '100%', md: '100%', lg: '40%', xl: '46%' }}
            display="flex"
            px={{
              base: '30px',
              sm: '30px',
              md: '40px',
              lg: '30px',
              xl: '100px',
            }}
            mt={{ base: '200px', md: '240px', lg: '0', xl: '0' }}
            pb="10px"
          >
            <Box
              fontSize={{ base: '18px', md: '20px', lg: '24px' }}
              fontWeight="normal"
              fontFamily={'FedraSansStd-book'}
              fontStretch="normal"
              fontStyle="normal"
              lineHeight="normal"
              letterSpacing="normal"
              textAlign="center"
              color="#fff"
            >
              <StructuredTextParser
                className="AboutIycSection"
                str={render(sectionContent?.body[4].sectionContent[0].body)}
                region={region}
                lang={lang}
                fontSize={{ base: '18px', md: '17px', xl: '24px' }}
                textAlign="center"
              />
            </Box>
          </Flex>

          {/* Banner Content Ends */}
        </Box>
      </Box>
      <style>
        {`
        .AboutIycSection p {
            margin-bottom: 30px !important;
            line-height: 1.42857143;
          }
          @media only screen and (max-width: 1024px) {
            .AboutIycSection p {
              font-size: 17px;
              text-align: center;
          }
        }
        `}
      </style>
    </Box>
  );
};

export default AboutIycSection;
