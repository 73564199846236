import { Box, Flex, Image } from '@chakra-ui/react';

const AboutWisdomSection = ({ sectionContent, region, lang }) => {
  return (
    <Box w="full" maxW={'1300px'} mx="auto">
      <Box width={'100%'} height="auto" background="#fffbef">
        <Box pt={{ base: '20px', lg: '120px' }} pb="60px" px="30px">
          <Flex flexDir="column" justifyContent="center" alignItems={'center'}>
            <Box
              maxW={{ md: '45px', base: '48px' }}
              maxHeight={{ md: '47px', base: '48px' }}
              mx="auto"
              mb="23px"
              display={{ base: 'block', md: 'none' }}
            >
              <Image
                src={sectionContent.body[1].sectionContent[1].image?.url}
                alt={sectionContent.body[1].sectionContent[1].image?.alt}
                title={sectionContent.body[1].sectionContent[1].image?.title}
              />
            </Box>
            <Box w={{ base: '100%', lg: '500px' }} height={'auto'}>
              {/* Title of the Quote */}
              <Box
                fontSize={{ base: '22px', md: '19px', lg: '24px' }}
                fontStretch="normal"
                fontFamily="FedraSansStd-medium"
                fontStyle="normal"
                lineHeight="1.1"
                letterSpacing="normal"
                mb="15px"
                textAlign="center"
                fontWeight="500"
                className="Title"
                color={'#731902'}
              >
                Tune in for a Daily Dose of Joy and Wisdom
              </Box>
            </Box>
            <Box
              className="desc"
              fontSize={{ base: '18px', md: '17px', lg: '20px' }}
              color={'#000'}
              fontFamily="FedraSansStd-book"
              fontStretch="normal"
              fontStyle="normal"
              lineHeight={{ base: '24px', lg: '28px' }}
              letterSpacing="normal"
              textAlign="center"
              fontWeight={'500'}
              w={{ base: '100%', lg: '500px' }}
            >
              {sectionContent.summary}
            </Box>

            <Box
              maxW={{ md: '45px', base: '30px' }}
              maxHeight={{ md: '47px', base: '31px' }}
              mt="26px"
              mx="auto"
              mb="23px"
              display={{ base: 'none', md: 'block' }}
            >
              <Image
                src={sectionContent.body[1].sectionContent[1].image?.url}
                title={sectionContent.body[1].sectionContent[1].image?.title}
                alt={sectionContent.body[1].sectionContent[1].image?.alt}

              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutWisdomSection;
