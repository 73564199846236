/* External Imports */
import { Box, Flex, Image, Link } from '@chakra-ui/react';

/**
 *
 */
const SocialSections = ({ sectionContent, region, lang }) => {
  // consoleLog(
  //   sectionContent.body[5].sectionContent[4]?.image?.url,
  //   'image url'
  // );
  return (
    <Box w="auto" m="0 auto" maxW={'1300px'}>
      <Flex
        flexDir={'column'}
        alignItems="center"
        justifyContent={'center'}
        w="100%"
        margin={0}
        padding="0"
        bgColor="#fffbef"
      >
        <Flex
          // justifyContent={'space-between'}
          justifyContent="space-around"
          mt={{ md: '82px', base: '44px' }}
          // maxW={{ md: '61px', base: '61px' }}
          maxH="64px"
          w="auto"
          padding="0"
          mb={{ md: '26px', base: '17px' }}
        >
          <Flex justifyContent={'flex-start'} flex={1}>
            <Link
              target="_blank"
              href={sectionContent?.body[5].sectionContent[0]?.linkUrl}
            >
              <Image
                h="100%"
                src={sectionContent?.body[5].sectionContent[0].image.url}
                alt={sectionContent?.body[5].sectionContent[0].image.alt}
                title={sectionContent?.body[5].sectionContent[0].image.title}
              />
            </Link>
          </Flex>
          <Flex justifyContent={'center'} alignItems="center" flex={1}>
            <Link
              target="_blank"
              href={sectionContent?.body[5].sectionContent[1]?.linkUrl}
            >
              <Image
                h="100%"
                src={sectionContent.body[5].sectionContent[1]?.image?.url}
                title={sectionContent.body[5].sectionContent[1]?.image?.title}
                alt={sectionContent.body[5].sectionContent[1]?.image?.alt}
              />
            </Link>
          </Flex>
          <Flex justifyContent={'center'} alignItems="center" flex={1}>
            <Link
              target="_blank"
              href={sectionContent?.body[5].sectionContent[2]?.linkUrl}
            >
              <Image
                h="100%"
                src={sectionContent.body[5].sectionContent[2]?.image?.url}
                alt={sectionContent.body[5].sectionContent[2]?.image?.alt}
                title={sectionContent.body[5].sectionContent[2]?.image?.title}
              />
            </Link>
          </Flex>
          <Flex justifyContent={'flex-end'} flex={1}>
            <Link
              target="_blank"
              href={sectionContent?.body[5].sectionContent[3]?.linkUrl}
            >
              <Image
                h="100%"
                src={sectionContent.body[5].sectionContent[3]?.image?.url}
                title={sectionContent.body[5].sectionContent[3]?.image?.title}
                alt={sectionContent.body[5].sectionContent[3]?.image?.alt}
              />
            </Link>
          </Flex>
        </Flex>
        <Flex
          alignItems={'center'}
          justifyContent="center"
          mb={{ md: '24px', base: '14px' }}
        >
          <Link
            target="_blank"
            href={sectionContent?.body[5].sectionContent[4]?.linkUrl}
          >
            <Image
              w={'228px'}
              alt="sadhguru-radio-mobile-app"
              title="sadhguru-radio-mobile-app"
              src={
                sectionContent.body[5].sectionContent[4]?.image?.url ||
                'https://images.sadhguru.org/sites/default/files/inline-images/sadhguru-radio-mobile-app.jpg'
              }
            />
          </Link>
        </Flex>
        <Box
          as="p"
          fontFamily="'FedraSansStd-medium'"
          fontSize={{ md: '27px', base: '17px' }}
          fontWeight="500"
          fontStretch="normal"
          fontStyle="normal"
          lineHeight="normal"
          mb={{ md: '62px', base: '70px' }}
          letterSpacing="normal"
          textAlign="center"
          color="#731902"
        >
          isha.sadhguru.org
        </Box>
      </Flex>
    </Box>
  );
};

export default SocialSections;
