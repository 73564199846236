import { Box, Flex } from '@chakra-ui/react';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';

const AboutSadhguruSection = ({ sectionContent, region, lang }) => {
  return (
    <Box w="100%" maxW={'1300px'} mx="auto">
      {/* Banner Starts */}
      <Box
        // minH={{ base: 'auto', lg: '745px' }}

        display="flex"
        justifyContent="flex-start"
        pt={{ base: '240px', md: '200px', lg: '44px', xl: '0' }}
        pb={{ base: '22px', lg: '44px', xl: '100px' }}
        backgroundPosition={{ base: 'top', md: 'right', lg: 'top' }}
        backgroundSize={{ base: 'contain', md: 'cover' }}
        backgroundRepeat="no-repeat"
        backgroundImage={{
          md: `url(${sectionContent.body[2]?.sectionContent[1].image.url})`,
          base: `url(${sectionContent.body[2]?.sectionContent[1]?.mobileImage?.url})`,
        }}
        backgroundColor="#02209c"
      >
        <Flex
          w={{ base: '100%', md: '100%', lg: '48%', xl: '39%' }}
          display="flex"
          p={{
            base: '0 30px',
            sm: '0 45px',
            xl: '92px 0px 0px 92px',
          }}
        >
          <Box
            fontSize={{ base: '18px', md: '20px', lg: '22px' }}
            fontWeight="normal"
            fontStretch="normal"
            fontStyle="normal"
            fontFamily={'FedraSansStd-book'}
            lineHeight={{ base: '20px', lg: 'normal' }}
            letterSpacing="normal"
            color="#fff"
            id="sr-text"
            textAlign="center"
          >
            <StructuredTextParser
              str={render(sectionContent?.body[2].sectionContent[0].body)}
              region={region}
              lang={lang}
              className="AboutSadhguru"
              fontSize={{ base: '18px', md: '17px', lg: '24px' }}
              textAlign={{ base: 'left', md: 'center', lg: 'center' }}
            />
          </Box>
        </Flex>
      </Box>
      {/* Banner Ends */}

      <style>
        {`
        
          .AboutSadhguru p {
            margin-bottom: 30px !important;
          }
          @media only screen and (max-width: 1200px) {
            .AboutSadhguru p {
              font-size: 20px;
              line-height: 1.42857143;
              text-align:left;
          }
          @media only screen and (max-width: 1024px) {
            .AboutSadhguru p {
              font-size: 17px;
              line-height: 1.42857143;
              text-align: center;
          }
          }
          @media only screen and (max-width: 768px) {
            .AboutSadhguru p {
              font-size: 18px;
              line-height: 1.42857143;
              text-align:center;
          }
          @media only screen and (max-width: 600px) {
            .AboutSadhguru p {
              font-size: 18px;
              line-height: 1.42857143;
              text-align:left;
          }
          }
        `}
      </style>
    </Box>
  );
};

export default AboutSadhguruSection;
