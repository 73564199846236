import { Box, Flex, Image } from '@chakra-ui/react';
import { useRef, useState } from 'react';
/**
 *
 * @returns
 */

const radioStreamingUrl = 'https://streaming.radio.co/s445d7b4c0/listen';

const TopBanner = ({ sectionContent, region, lang }) => {
  return (
    <Box
      mx="auto"
      h={{ xl: '775px', md: '600px', sm: '870px', base: '545px' }}
      className="sadhguruRadio"
      background="#9C3700"
      maxW={'1300px'}
    >
      <Box w={'100%'} h="100%">
        <Box
          w="100%"
          h={{ md: '100%', sm: '747px', base: '100%' }}
          backgroundImage={{
            md: `url(${sectionContent.sectionContent[0]?.image?.url})`,
            sm: `url(${sectionContent.sectionContent[0]?.mobileImage?.url})`,
            base: `url(${sectionContent.sectionContent[0]?.mobileImage?.url})`,
          }}
          backgroundPosition={{ md: 'center', sm: 'center', base: 'center' }}
          backgroundSize={'cover'}
          objectFit="cover"
          backgroundRepeat="no-repeat"
        >
          <Box h={{ md: '157px', base: '272px' }}></Box>
          <Box
            // mr={{ base: '65px', sm: 'auto', md: '911px' }}
            ml={{ md: '96px', sm: 'auto', base: 'auto' }}
            width={{ xl: '433px', md: '303px', sm: '433px', base: '226px' }}
            mr="auto"
            display="flex"
            mt={{ sm: '100px', base: '0', md: '0' }}
            alignItems="center"
            flexDir="column"
          >
            <Box
              width={{ xl: '433px', md: '303px', sm: '433px', base: '226px' }}
              height={{ md: 'auto', sm: 'auto', base: 'auto' }}
            >
              <Image
                objectFit={'contain'}
                width="100%"
                h="100%"
                src={sectionContent.sectionContent[1]?.image?.url}
                alt={sectionContent.sectionContent[1]?.image?.alt}
                title={sectionContent.sectionContent[1]?.image?.title}
              />
            </Box>

            <Box
              mt={{ md: '27px', base: '16px' }}
              width={{ md: '433px', sm: '226px', base: '226px' }}
            >
              <Flex
                alignItems={'center'}
                justifyContent="center"
                w={{ xl: '104px', md: '59px', sm: '59px', base: '59px' }}
                h={{ md: '104px', sm: '59px', base: '59px' }}
                m="0 auto"
              >
                <PlayRadio
                  playImage={sectionContent.sectionContent[2]?.image?.url}
                  pauseImage={sectionContent.sectionContent[3]?.image?.url}
                />
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const PlayRadio = ({ playImage, pauseImage }) => {
  const radioRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const videoHandler = control => {
    if (control === 'play') {
      radioRef.current.play();
      setPlaying(true);
    } else if (control === 'pause') {
      radioRef.current.pause();
      setPlaying(false);
    }
  };

  return (
    <Box>
      <Box as="video" ref={radioRef} src={radioStreamingUrl} display="none" />
      <Image
        onClick={() => videoHandler(playing ? 'pause' : 'play')}
        src={playing ? pauseImage : playImage}
        objectFit={'contain'}
        h="100%"
        w="100%"
        loading="lazy"
        cursor="pointer"
        alt=""
      />
    </Box>
  );
};

export default TopBanner;
