import { Box, Flex } from '@chakra-ui/react';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';

const AboutIiiSection = ({ sectionContent, region, lang }) => {
  return (
    <Box backgroundColor="#3f4d03" maxW={'1300px'} mx="auto">
      <Box w="full">
        <Box
          display="flex"
          justifyContent={{
            base: 'flex-end',
            md: 'normal',
            lg: 'flex-end',
          }}
          backgroundPosition={{ base: 'top', md: 'center' }}
          backgroundSize={{ base: 'contain', md: 'cover' }}
          backgroundRepeat="no-repeat"
          backgroundImage={{
            lg: `url(${sectionContent.body[3]?.sectionContent[1].image.url})`,
            md: `url(${sectionContent.body[3]?.sectionContent[1]?.mobileImage?.url})`,
            base: `url(${sectionContent.body[3]?.sectionContent[1]?.mobileImage?.url})`,
          }}
        >
          <Flex
            w={{ base: '100%', md: '100%', lg: '40%' }}
            display="flex"
            py={{ base: '30px', md: '20px', lg: '50px', xl: '85px' }}
            px={{ base: '30px', lg: '5%' }}
            // p={{ md: '60px 30px 0px 30px;' }}
            mt={{ base: '200px', sm: '100px', md: '250px', lg: '0' }}
          >
            <Box
              fontSize={{ base: '18px', md: '20px', lg: '24px' }}
              fontWeight="normal"
              fontStretch="normal"
              fontStyle="normal"
              fontFamily={'FedraSansStd-book'}
              lineHeight="normal"
              letterSpacing="normal"
              textAlign={{ md: 'center', base: 'left' }}
              color="#fff"
            >
              <StructuredTextParser
                str={render(sectionContent?.body[3].sectionContent[0].body)}
                region={region}
                lang={lang}
                fontSize={{ base: '18px', md: '20px', lg: '24px' }}
                textAlign="center"
                className="AboutII"
              />
            </Box>
          </Flex>

          {/* Banner Content Ends */}
        </Box>
      </Box>
      <style>
        {`
          .AboutII p {
            margin-bottom: 30px !important;
          }
          .AboutII{
            max-width:100%;
          }
          @media only screen and (max-width: 1200px) {
            .AboutII p {
              font-size: 20px;
              line-height: 1.42857143;
              text-align:center;
          }
          @media only screen and (max-width: 1024px) {
            .AboutII p {
              font-size: 17px;
              text-align: center;
          }
          @media only screen and (max-width: 600px) {
            .AboutII p {
              font-size: 18px;
              text-align: left;
          }
          }
        `}
      </style>
    </Box>
  );
};

export default AboutIiiSection;
