/* Built In Imports */
import { useContext, useEffect } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Breadcrum from '@components/Navigation/Breadcrum';
import StyleConfig from '@components/UI/Common/StyleConfig';
import HeadComponent from '@components/Utility/HeadComponent';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */
import {
    getLocale
} from '@components/Utility/Shared/SharedService';
import AboutIiiSection from '@landing_pages/SadhguruRadio/AboutIiiSection';
import AboutIycSection from '@landing_pages/SadhguruRadio/AboutIycSection';
import AboutSadhguruSection from '@landing_pages/SadhguruRadio/AboutSadhguruSection';
import AboutWisdomSection from '@landing_pages/SadhguruRadio/AboutWisdomSection';
import SocialSections from '@landing_pages/SadhguruRadio/SocialSections';
import TopBanner from '@landing_pages/SadhguruRadio/TopBanner';
import {
    fetchFooterData, fetchHeaderMenuData, fetchHeaderTopMenuData
} from 'services/commonService';
import { fetchDetails, getLandingPaths } from 'services/othersService';

/* Styles */

/**
 *
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - Search Landing Page Data
 */
const SadhguruRadio = ({ region, language, pageData }) => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.RegionAndLang(region, language);
  }, [authContext, region, language]);

  // consoleLog('page====>', pageData);

  return (
    <Box float="left" w="100%">
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Breadcrum
        page={pageData.breadcrumbs}
        alsoin={pageData.alsoin}
        url=""
        region={region}
        lang={language}
        pageConfig={pageData?.pageConfig}
      />

      <Box as="section">
        {pageData.body && pageData.body.length
          ? pageData.body.map((section, i) => {
              if (section.sectionId === 'top-banner') {
                return (
                  <StyleConfig
                    id={section?.sectionId}
                    key={section.sectionId}
                    style={section?.styleConfig}
                  >
                    <TopBanner
                      sectionContent={section}
                      region={region}
                      lang={language}
                    />
                  </StyleConfig>
                );
              } else if (section.sectionId === 'about-wisdom-section') {
                return (
                  <AboutWisdomSection
                    sectionContent={pageData}
                    region={region}
                    lang={language}
                  />
                );
              } else if (section.sectionId === 'about-sadhguru-section') {
                return (
                  <StyleConfig
                    id={section?.sectionId}
                    key={section.sectionId}
                    style={section?.styleConfig}
                  >
                    <AboutSadhguruSection
                      sectionContent={pageData}
                      region={region}
                      lang={language}
                    />
                  </StyleConfig>
                );
              } else if (section.sectionId === 'about-iii-section') {
                return (
                  <StyleConfig
                    id={section?.sectionId}
                    key={section.sectionId}
                    style={section?.styleConfig}
                  >
                    <AboutIiiSection
                      sectionContent={pageData}
                      region={region}
                      lang={language}
                    />
                  </StyleConfig>
                );
              } else if (section.sectionId === 'about-iyc-section') {
                return (
                  <StyleConfig
                    id={section?.sectionId}
                    key={section.sectionId}
                    style={section?.styleConfig}
                  >
                    <AboutIycSection
                      sectionContent={pageData}
                      region={region}
                      lang={language}
                    />
                  </StyleConfig>
                );
              } else if (section.sectionId === 'social-sections') {
                return (
                  <StyleConfig
                    id={section?.sectionId}
                    key={section.sectionId}
                    style={section?.styleConfig}
                  >
                    <SocialSections
                      sectionContent={pageData}
                      region={region}
                      lang={language}
                    />
                  </StyleConfig>
                );
              }
            })
          : null}
        {/* <TopBanner
                sectionContent={pageData}
                region={region}
                lang={language}
              /> */}
        {/* <RadioQuote sectionContent={pageData} region={region} lang={language} />
        <RadioBanner
          sectionContent={pageData}
          region={region}
          lang={language}
        />
        <RadioBanner2
          sectionContent={pageData}
          region={region}
          lang={language}
        />
        <RadioBanner3
          sectionContent={pageData}
          region={region}
          lang={language}
        />
        <Social sectionContent={pageData} region={region} lang={language} /> */}
      </Box>
    </Box>
  );
};

export const getStaticPaths = async () => {
  const sadhguruRadioPaths = config.USE_LOCAL_URL
    ? [`/in/en/sadhguru-radio`]
    : await getLandingPaths('lvl0', '/sadhguru-radio');
  return {
    paths: sadhguruRadioPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, language },
  req,
}) => {
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale(region, language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale(region, language),
    'isomainmenu'
  );
  const footerData = await fetchFooterData(
    getLocale(region, language),
    'isofooter'
  );
  const pageData = await fetchDetails(region, language, `sadhguru-radio`,
    { preview, data: previewData });

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region: region,
      language: language,
      footerData: footerData?.data || null,
      pageData: pageData || null,      
      isPreview: preview ? true : false
    }
  };
};

export default SadhguruRadio;
